.backarrow{
  background: rgba(38, 70, 83, 0.5);
  border-radius: 50%;
  fill: #F4A261;
  width: 30px;
  height: 30px;
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.rules {
  /*scroll*/
    overflow-y: scroll;
  padding:0 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #264653;
  gap: 50px;
}

.rules h1 {
  margin: 0;
  margin-top: 80px;
  font-family: K2D, serif;
  font-size: 40px;
  color: white;
}

.rules-content {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 100px;
}

.rules-content h2 {
  align-self: center;
  font-family: K2D, serif;
  font-size: 25px;
  color: #F4A261;
  font-style:italic;
  margin: 0;
  margin-bottom: 20px;
}

.rules-content p {
  text-align: justify;
  align-self: flex-start;
  font-family: K2D, serif;
  font-size: 15px;
  color: white;
  margin: 0;
  margin-bottom: 20px;
}

.rules-content ul {
  margin-top:0;
  margin-bottom:10px;
}

.rules-content li {
  font-size: 15px;
  color: white;
}

.rules-content li p{
  margin: 0;
  margin-bottom: 5px;
}
.rules-content li span {
  margin-left:10px;
}

.credits{
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  align-content: stretch;


}

.credits li{
  height: 100%;
  width: calc(50% - 30px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: rgba(78, 144, 169, 0.5);
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.2);
}


.credits span{
  text-align: center;
  font-family: K2D, serif;
  font-size: 13px;
  color: #cecece;
  margin: 0 !important;
}

.credits a
{
    font-family: K2D, serif;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.1em;
    color: #F4A261;
    text-decoration: none;
}


.flip-container{
  width:80%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.circle
{
  position:relative;
  width:100%;
  padding-top:100%;
  -webkit-border-radius:50%;
  border-radius:50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;

  /* Assign the perspective to enable 3d Transform */
  perspective :300px;
  -webkit-perspective: 300px;
  -moz-perspective: 300px;

}

.circle  img{

  border: 2px solid #F4A261;
    width:100%;
    height:100%;
  border-radius:50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.circle .front-img
{
  position:absolute;

  width:100%;
  height:100%;
  top:0;
  z-index:9;

  /* Assign The 3D Transform */
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;

  /* Transition Effect */
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
}
/* .flipping class for flip effect using jquery */

.circle.flipping .front-img
{
  z-index:9;
  -webkit-transform:rotateY(180deg);
  -moz-transform:rotateY(180deg);
}


.circle .back-img
{
  position:absolute;

  top:0;
  width:100%;
  height:100%;
  z-index:8;
  /* Create Circle With 50% Rounded */
  border-radius:50%;
  -webkit-border-radius:50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;

  /* Assign The 3D Transform */
  -webkit-transform:rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-transform:rotateY(-180deg);
  -moz-transform-style: preserve-3d;
  -moz-backface-visibility: hidden;

  /* Transition Effect */
  transition:all .2s ease-in-out;
  -webkit-transition:all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;
}



.circle.flipping .back-img
{
  z-index:10;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform:rotateX(0deg) rotateY(0deg);
}
