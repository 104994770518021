
.backarrow{
    fill: #F4A261;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

.admin{
    box-sizing: border-box;
    font-family: K2D, serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    min-height: 100vh;
    padding: 50px 20px;
    background: #264653;
    color: #fff;
}

.admin h1{
    text-align: center;
    align-self: center;
    margin:0;
    padding:0;
    font-size: 40px;
}

.admin h2{
    align-self: center;
    font-size: 25px;
    color: #F4A261;
    margin: 0;
}

.admin h3{
    /*key point*/
    text-align: center;
    align-self: center;
    font-size: 30px;
    color: #ffffff;
    margin: 0;
}

.admin p{
    text-align: center;
    align-self: center;
    font-size: 15px;
    color: #cecece;
    margin: 0;
}


.admin-content{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap: 20px;
}



.admin-stats{
    width: 100%;
    height: fit-content;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap: 20px;
}

.admin-stats-content{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap: 20px;
}

.admin-users{
    width: 100%;
    height: fit-content;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap: 20px;
}


.admin-users-content{
    /* scroll if content is too big */
    height: 35vh;
    animation: slideFromBottom 0.5s ease-out, fadeOn 1s ease-out;

    border-radius: 20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
}



.admin-users-search{
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
}
.admin-users-search input {
    border-radius: 20px 20px 0px 0px;
    box-sizing: border-box;
    padding-left: 40px;
    width:70vw;
    height: 50px;
    border: none;
    color: white;
    font-size: 15px;
    background: none;
    border-bottom: 2px solid #fff;
    /*box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.25);
*/
}

.admin-users-search input::placeholder{
    color: #cecece;
}

.admin-users-search input:focus-visible{
    outline: none;
}

.admin-users-list{
    overflow-y: scroll;
    width: 100%;
    height: 100%;

}

.user-admin{
    cursor: pointer;
    width: 100%;
    height: fit-content;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
}

.user-admin:active img, .user-admin:active span{
    /*slide to right*/
    scale:1.1;
    transition: transform 0.1s ease-out;

}


.user-admin img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px 20px 10px 10px;
}

.user-admin span{
    font-size: 20px;
    font-weight: normal;
    color: #fff;
}

.admin-star{
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
    margin: 10px 20px 10px 10px;
}

.admin-user-content>span{
    position: fixed;
    bottom: 60px;
    width:70%;
    padding: 10px 0;
    border-radius: 20px;
    text-align: center;
    font-family: K2D, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
    background: #E76F51;
    color: #fff;
}

.admin-users-selected{
    width: 100%;
    height: fit-content;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
}

.admin-users-selected .admin-user-selected{
    font-size: 20px;
    font-weight: normal;
    color: #fff;
}

/*if the text inside the span is admin, change the color to yellow*/
.admin-user-carac, .normal-user-carac{
    color: #ffd000;
    font-size: 17px;
    font-style: italic;
}

.normal-user-carac{
    color: #2A9D8F;
}

.admin-user-buttons{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-evenly;
    gap:10px;
}

.admin-users-delete, .admin-users-setadmin, .admin-users-setuser{
    width: 100%;
    margin-top:10px;
    border-radius: 20px;
    text-align: center;
    font-family: K2D, sans-serif;
    font-size: 15px;
    line-height: normal;
    border: none;
    cursor: pointer;
    background: #E76F51;
    color: #fff;
    padding: 8px 15px;
}

.admin-users-setadmin{
    /*promouvoir*/
    background: #2A9D8F;
}

.admin-users-setuser{
    /*rétrograder*/
    background: #F4A261;
}

