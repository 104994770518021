




.donne-popup{
    z-index:2;
    animation: fadeOn 0.5s ease-out;
    display:flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    align-items:center;
    justify-content:center;
}

.donne-popup-content{
    /* scroll if content is too big */
    overflow-y: scroll;
    animation: slideFromBottom 0.5s ease-out, fadeOn 1s ease-out;
    width: 80%;
    height: 90%;
    background: #D9D9D9;
    border-radius: 20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap:20px;
    padding-top: 20px;
}

@keyframes slideFromBottom{
    /* little fade to UP */
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeOn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.game-popup-banner{
    width: 100%;
}

.donne-popup-form{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    gap:20px;
}

.donne-popup-form-input{
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: 20px;


}

.donne-popup-form-input>div{
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    gap:5px;
}

.donne-popup-form-input>div input{
    width: 80%;
    height: 30px;
    border: 2px solid #264653;
    border-radius: 20px;
    padding-left: 20px;
    font-family: K2D, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: #264653;

}
.donne-popup-form-title, label{
    justify-self: center;
    font-family: K2D, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2;
    color: #264653;
    margin: 0;
    padding: 0;
}

label{
    font-size: 20px;
    color: #264653;
}

.donne-popup-banner img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.players{
    width: 100%;
    align-self: flex-start;
    margin : 10px 0;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    gap:10px;
    flex-wrap:wrap;
    margin-top: 20px;

}



.button-area{
    height: 100%;
    margin-bottom: 20px;
    align-self: flex-end;
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-end;
    gap:15px;
    padding-bottom: 20px;
}

.button-area span, .button-area button{
    width:80%;
    padding: 10px 0;
    border-radius: 20px;
    text-align: center;
    font-family: K2D, sans-serif;
    font-size: 20px;
    background: #F4A261;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
    color: #fff;
}

.button-area span{
    background: #E76F51;
    color: #fff;
}

.delete{
    width: 10px;
    height: auto;
    position: absolute;
    top: -20px;
    right: -10px;
}


.players_cards_add_donne{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.players_cards_add_donne > div {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 10px;
}

.players_cards_add_donne > div > img {
    width: 60px;
    height: auto;
    border-radius: 50%;
}

.players_cards_add_donne > div > p {
    text-align: center;
    margin: 0;
}


.contrat{
    display: flex;
    justify-content: space-between;
}

.contrat div{
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.contrat p {
    border: 2px solid #333;
    padding: 5px 10px;
    border-radius: 10px;
    margin: 0;
}

.contrat_valide{
    display: flex;
    justify-content: space-between;
}

.contrat_valide p {
    border: 2px solid #333;
    padding: 5px;
    border-radius: 5px;
}



.points > input {
    width: 40% !important;
}