@font-face {
    font-family: 'K2D';
    src: url('../../fonts/K2D-Regular.ttf');
    font-style: normal;
    font-weight: 400;
    font-display: swap;

}

.menu{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.menu ul{
    list-style: none;
    margin: 0;
    padding: 0;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:40px;
}



.menu a{
    text-decoration: none;
    width: 100%;
    padding: 15px;
    border-radius: 15px;

    background: #D9D9D9;
    color: #000;

    text-align: center;
    font-family: K2D, serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    box-shadow: -3px 3px 0 0 rgba(159,159,159,1);
    transition: 0.3s;
}

.menu a:active{
    transform: scale(0.95);
    border-radius: 15px;
    box-shadow: -3px 3px 0 0 rgba(159,159,159,1);
}

.menu p{
    color: #F4A261;
    font-family: K2D, serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
}