.users-list{
    animation: fadeOn 0.5s ease-out;
    display:flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.5);
    align-items:center;
    justify-content:center;
}

.users-list-content{
    overflow-x: clip;
    overflow-y: scroll;
    animation: slideFromBottom 0.5s ease-out, fadeOn 1s ease-out;
    width: 80%;
    height: 90%;
    background: #D9D9D9;
    border-radius: 20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
}

@keyframes slideFromBottom{
    /* little fade to UP */
    from{
        transform: translateY(100px);
        opacity: 0;
    }
    to{
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeOn {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

.users-list-search{
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
}
.users-list-search input{
    padding-left:50px;
    width: 100%;
    height: 50px;
    border: none;
    font-size: 20px;
    background: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.users-list-search input:focus-visible{
    outline: none;
}


.user{
    cursor: pointer;
    width: 100%;
    height: fit-content;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: flex-start;
    /* bottom box-shadow */
    border-bottom: 1px solid #000;
}

.user:active img, .user:active span{
    /*slide to right*/
    scale:1.1;
    transition: transform 0.1s ease-out;

}


.user img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 10px 20px 10px 10px;
}

.user span{
    font-size: 20px;
    font-weight: bold;
}

.users-list-content>span{
    position: fixed;
    bottom: 60px;
    width:70%;
    padding: 10px 0;
    border-radius: 20px;
    text-align: center;
    font-family: K2D, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    border: none;
    cursor: pointer;
    background: #E76F51;
    color: #fff;
}