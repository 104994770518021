footer{
    width: 100%;
    display: flex;
    justify-content: center;
}

footer div{
    width:100%;
    margin:0 0 30px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

footer a{
    text-decoration: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background:#D9D9D9;
    padding: 15px;
    transition: 0.3s;
}

.avatar{
    width: 60px;
    height: 60px;
    padding:0 !important;
}

.avatar img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

footer a:active{
    transform: scale(0.95);
}

footer a img{
    width: 100%;
}

