.backarrow{
    fill: #F4A261;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 20px;
    left: 20px;
    cursor: pointer;
}
.account_page_root{
    width:100vw;
    height:100vh;
}
.account_page{
    height: 100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-evenly;
    background: #264653;
}

.account_logo{
    margin:0;
    padding:0;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.errorAccount{
    font-size: 20px !important;
}

.account_page form{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.account_page input{
    text-decoration: none;
    width: 70%;
    padding: 15px;
    border-radius: 15px;
    background: #D9D9D9;
    color: #000;
    font-family: K2D, serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: -3px 3px 0 0 rgba(159,159,159,1);
    margin-bottom: 20px;
    border:none;
    /* text should be at left */
    text-align: left;
}

.account_page input[type="submit"]{
    margin-top: 20px;
    text-align: center;
    transition: 0.2s;
}

.account_page input[type="submit"]:active{
    transform: scale(0.95);
    transition: 0.2s;
}

.account_page p{
    font-family: K2D, serif;
    font-size: 22px;
    color:white;
    margin:0;
    text-align: center;
    padding:0 20px;
}

.account_page h2{
    font-family: K2D, serif;
    font-size: 40px;
    font-weight: 500;
    color:#fff;
    margin:0;
    text-align: center;
    padding:0 20px;
}

.username_account{
    margin-bottom: 20px !important;
}

.change_type,.deconnexion,.delete_account{
    font-weight: 700;
    text-decoration: underline;
}

.account_page .pp_list{
    position:relative;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
    padding:0 30px;
    box-sizing: border-box;
    overflow-x: scroll;
}

.pp_list::-webkit-scrollbar{
    display: none;
}

.account_page .pp{
    width: 80px;
    height: auto;
    opacity: 0.5;
    transition: 0.3s;
    border-radius: 50%;
}


.account_page .ppSelected {
    animation: spin 4s linear infinite;
    width: 80px;
    height: auto;
    transition: 0.3s;
    scale: 1.1;
    border-radius: 50%;
}

.username_form{
    margin-bottom: 20px !important;
}