/* font*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
/* italic*/
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&italic&display=swap');


.card{
    width: 80vw;
    background: #D9D9D9;
    border-radius: 15px;
    /* inter font */
    font-family: K2D, serif;
    font-weight: 400;
    transition: 0.3s;
    text-decoration: none;
    color: #000;
}



.card:active{
    transform: scale(0.97);
}

.top-card{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 10px;
}

.top-left-card, .top-right-card{
    font-size: 15px;
    margin-top: 10px;
    /* faire en sort que les lettres prennent moins de hauteur inutile (ex: le "p" prend trop de hauteur) */
    line-height: 1.2;
}

.top-middle-card{
    font-size: 20px;
    color: #FFF;
    background: #264653;
    height: fit-content;
    display: flex;
    flex-direction: row;
}
.corner-color-div{
    background: #D9D9D9;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.top-middle-card span{
    text-align: center;
    background: #264653;
    padding: 0 15px 5px 15px;
    border-radius: 0 0 15px 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    width:100px;
    height: 1.2em;
    white-space: nowrap;
}


.corner-left, .corner-right{
    width: 10px;
    background: #D9D9D9;
}

.corner-left{
    border-top-right-radius: 15px;
}

.corner-right{
    border-top-left-radius: 15px;
}
.top-left-card, .top-right-card{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.value{
    font-size: 30px;
}





.middle-card{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    font-size: 20px;
    margin: 0 15px;
    padding: 5px 0;
    margin-bottom: 20px;
}

.middle-card>div{
    display: flex;
    font-size: 15px;
    align-items: center;
}

.middle-card>div>img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 50%;
}

.middle-card > div > span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 5px;
}

.middle-card>p{
    margin:0;
    padding:0;
}

.score>span,.middle-card>p>span{
    font-size: 15px;
    font-style: italic;
    line-height: normal;
}


